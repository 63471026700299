<template>
  <article
    component-name="menu-professional"
    data-lenis-prevent
    class="overflow-y-auto overscroll-y-contain grid grid-cols-12 gap-ui bg-skin-base h-full max-h-full [&>*]:max-h-full px-edge"
  >
    <section class="w-full col-span-full laptop:col-start-1 laptop:col-end-5">
      <header
        class="sticky top-0 left-0 flex flex-col items-start justify-start w-full gap-3xl py-2xl laptop:pt-5xl"
      >
        <div class="flex flex-col w-full">
          <wrapper-split-text reveal="words">
            <div
              class="text-heading-5"
              v-html="$t.titles.menu_professional_title"
            ></div>
          </wrapper-split-text>

          <wrapper-reveal class="w-full mt-2xl" reveal="slide-from-right">
            <btn-pill
              :title="$t.labels.request_access"
              size="small"
              icon="arrow-long-right"
              :action="{ href: campusUrl }"
            />
          </wrapper-reveal>

          <wrapper-reveal reveal="slide-from-bottom" class="mt-md">
            <div
              class="text-body-small text-skin-muted"
              v-html="$t.texts.menu_professional_text"
            ></div>
          </wrapper-reveal>
        </div>

        <btn-list class="w-full" size="small" :items="btnListItems" />
      </header>
    </section>

    <section
      class="flex flex-col items-stretch w-full col-span-full laptop:col-start-6 laptop:col-end-13 gap-2xl pb-[calc(100lvh-100svh)] laptop:pb-2xl laptop:pt-2xl mb-2xl laptop:mb-0"
    >
      <card-launcher
        v-for="item in data.items"
        class="w-full"
        :overline="item.overline"
        :title="item.title"
        :title-details="item.totalProducts || undefined"
        :text="item.text || undefined"
        :action="item.action"
        :asset="{
          ...item.asset,
          aspectRatio: isLaptop ? '3 / 1' : '3 / 2',
          sizes: {
            base: 600,
            phablet: 1020,
            tablet: 1272,
            laptop: 756,
            desktop: 960,
            extdesktop: 1280,
          },
        }"
      />
    </section>
  </article>
</template>

<script setup>
const { storeLocatorEnabled } = useGlobals();
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
const isLaptop = useMediaQuery("(min-width: 1272px)");
const route = useRoute();
const nuxtApp = useNuxtApp();
const campusUrl = computed(() => {
  let lang;

  switch (route.params.locale) {
    case "it":
      lang = "it";
      break;

    case "es":
      lang = "it";
      break;

    case "de":
      lang = "it";
      break;

    case "fr":
      lang = "it";
      break;

    default:
      lang = "en";
      break;
  }

  return `https://campus.mirage.it/${lang}/account/signup`;
});

const btnListItems = computed(() => {
  let arr;
  if (isLaptop.value) {
    arr = [];
    arr.push({
      title: nuxtApp.$t.labels.mirage_catalogs,
      icon: "download",
      action: { to: "/resources/collections" },
    });

    if (storeLocatorEnabled.value) {
      arr.push({
        title: nuxtApp.$t.labels.find_reseller,
        icon: "store-locator",
        action: { to: "/store-locator" },
      });
    }

    arr.push({
      title: nuxtApp.$t.labels.info_request,
      icon: "info",
      action: { to: "/support" },
    });

    arr.push({
      title: nuxtApp.$t.labels.projects,
      icon: "world",
      action: { to: "/projects-and-stories/projects" },
    });
  }

  return arr;
});
</script>
